import { AxiosResponse } from "axios";
import { ITrainingSet, ITrainingSets, ITrainingSetWithArticle } from "../models/trainingSet";
import { contentClient } from "../apiClient";
import { IArticle } from "../models/article";

export const GetAllTrainingSets = async(perPage:number = 30, page:number = 1, name:string = ''):Promise<ITrainingSets> => {
    try {
        const response: AxiosResponse<ITrainingSets> = await contentClient().get('/trainingset', {
            params: {
                page,
                perPage,
                name,
            },
            });
            return response.data;
    } catch (error) {
        console.error('Error fetching training sets:', error);
        throw new Error('Failed to fetch training sets');
    }
}

export const GetTrainingSet = async(id:number):Promise<ITrainingSetWithArticle> => {
    try {
        const tsResponse: AxiosResponse<ITrainingSet> = await contentClient().get(`/trainingset/${id}`);
        const articleResponse: AxiosResponse<{data: IArticle[]}> = await contentClient().get(`/article/contentList/${id}/false`)
        
        const response: ITrainingSetWithArticle = {
            ...tsResponse.data,
            articles: articleResponse.data.data
        }
        return response

    } catch (error) {
        console.error('Error fetching training set:', error);
        throw new Error('Failed to fetch training set');
    }
}