import { FC, useState, useEffect } from "react";
import { NameLogoBlue } from "../assests";
import { Divider, Box, Grid, Typography } from "@mui/material";
import {
  CommonAlert,
  CommonLoading,
  SubscriptionPaymentAddress,
  SubscriptionPaymentCards,
  SubscriptionPaymentSummary,
} from "../components";
import { useNavigate, useParams } from "react-router-dom";
import { Gender, MarriageStatus, ParentType } from "../client";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { updateUser } from "../redux/authSlice";
import {
  INewCreditCard,
  IPackagePaymentAddress,
  ISelectCardForPayment,
} from "../utils/commonModels";
import { UseMetaConversation } from "../hooks";
import { paymentClient } from "../apiClient/apiClient";
import { PackageData } from "../apiClient/models/package";
import {
  InstallmentData,
  InstallmentResponse,
} from "../apiClient/models/installment";
import { MetaEventNames } from "../utils/constants";

const SubscriptionPayment: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { profile, loading: authLoading } = useSelector(
    (state: RootState) => state.auth
  );
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [phase, setPhase] = useState("address");
  const [invoiceAddress, setInvoiceAddress] = useState<IPackagePaymentAddress>({
    nameSurname: profile.name ? profile.name : "",
    country: "Türkiye",
    city: "",
    county: "",
    invoiceAddress: "",
  });
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [creditCard, setCreditCard] = useState<INewCreditCard>({
    card_holder_name: "",
    card_number: "",
    expiration_date: "",
    cvc: "",
  });
  const [paymentError, setPaymentError] = useState<string>("");
  const [selectedSavedCard, setSelectedSavedCard] = useState<ISelectCardForPayment>(
    {
      card_bin_number: "",
      card_last_four_digits: "",
    }
  );
  const [packageDetails, setPackageDetails] = useState<PackageData>();
  const [selectedInstallment, setSelectedInstallment] = useState<number>(1);
  const [installmentData, setInstallmentData] = useState<InstallmentData | null>(
    null
  );
  const [bankNameForIcon, setBankNameForIcon] = useState<string>("");
  const [cardAssociationForIcon, setCardAssociationForIcon] = useState<string>("");

  // Discount coupon states
  const [discountCouponCode, setDiscountCouponCode] = useState<string>('');
  const [discountCouponDetails, setDiscountCouponDetails] = useState<any>(null);
  const [discountCouponError, setDiscountCouponError] = useState<string>('');
  const [paidPrice, setPaidPrice] = useState<string>('0');

  useEffect(() => {
    if (id && !profile.is_user_premium) {
      fetchPackageDetails();
    } else {
      navigate("/home");
    }
  }, [id, profile.is_user_premium, navigate]);

  useEffect(() => {
    if (packageDetails) {
      setPaidPrice(packageDetails.price);
    }
  }, [packageDetails]);

  useEffect(() => {
    const binNumber = creditCard.card_number.replaceAll(" ", "")
    const priceString = paidPrice;
    if (binNumber.length === 6 && priceString) {
      const price = Number(priceString);
      if (!isNaN(price)) {
        fetchInstallmentOptions(binNumber.substring(0,6), price);
      }
    }
  }, [creditCard.card_number, paidPrice]);

  const fetchPackageDetails = async () => {
    try {
      setLocalLoading(true);
      const response = await paymentClient().get<{ data: PackageData }>(
        `/pricingPackage/${id}`
      );
      setPackageDetails(response.data.data);
    } catch (error) {
      console.error("Error fetching package details", error);
    } finally {
      setLocalLoading(false);
    }
  };

  const fetchInstallmentOptions = async (binNumber: string, price: number) => {
    try {
      const response = await paymentClient().get<InstallmentResponse>(
        `/payment/installment?price=${price}&binNumber=${binNumber}`
      );
      const data = response.data.data[0];

      setInstallmentData(data);
      setBankNameForIcon(data.bankName);
      setCardAssociationForIcon(data.cardAssociation);
    } catch (error) {
      console.error("Error fetching installment options:", error);
      setInstallmentData(null);
    }
  };

  const applyDiscountCoupon = async () => {
    try {
      setLocalLoading(true);
      const response = await paymentClient().get(
        `/discountCoupon/${discountCouponCode}`
      );
      if (response.data.success) {
        setDiscountCouponDetails(response.data.data);
        setDiscountCouponError('');
        // Adjust the price based on the discount
        const discountPercent = parseFloat(response.data.data.discountPercent);
        const originalPrice = Number(packageDetails?.price);
        const discountedPrice = originalPrice - (originalPrice * discountPercent / 100);
        setPaidPrice(discountedPrice.toFixed(2));
      } else {
        setDiscountCouponError('Geçersiz indirim kodu.');
      }
    } catch (error) {
      console.error('Error applying discount coupon:', error);
      setDiscountCouponError('Geçersiz indirim kodu.');
    } finally {
      setLocalLoading(false);
    }
  };

  const onContinueDisabled = (): boolean => {
    if (phase === "address") {
      return !(
        invoiceAddress.nameSurname.trim().split(" ").length >= 2 &&
        invoiceAddress.country.length > 2 &&
        invoiceAddress.city.length > 2 &&
        invoiceAddress.county.length > 2 &&
        invoiceAddress.invoiceAddress.length > 5
      );
    } else {
      const isCreditCardValid =
        creditCard.card_holder_name.trim().split(" ").length >= 2 &&
        creditCard.card_number.replaceAll(" ", "").length >= 16 &&
        creditCard.cvc.length === 3 &&
        creditCard.expiration_date.replaceAll("/", "").length === 4;

      const isSavedCardSelected =
        selectedSavedCard.card_bin_number.length > 2;

      return !(isCreditCardValid || isSavedCardSelected);
    }
  };

  const onContinueTriggered = async () => {
    try {
      if (phase === "address") {
        try {
          await dispatch(
            updateUser({
              user: {
                email: profile.email,
                phone: profile.phone,
                name: invoiceAddress.nameSurname,
                gender: profile.gender ? profile.gender : Gender.OTHER,
                parent_type: profile.parent_type
                  ? profile.parent_type
                  : ParentType.OTHER,
                marriage_status: profile.marriage_status
                  ? profile.marriage_status
                  : MarriageStatus.OTHER,
                address: invoiceAddress.invoiceAddress,
                country: invoiceAddress.country,
                city: invoiceAddress.city,
                county: invoiceAddress.county,
              },
            })
          );
        } catch (error) {
          console.error("Error updating user details:", error);
        }

        setPhase("payment");
        UseMetaConversation({
          event_name: MetaEventNames.purchaseWithLoginPageAddressPhase,
          user_id: profile.id,
          custom_data: { url: "/package-payment", phase: "address" },
        });
      } else {
        setLocalLoading(true);

        const [expiryMonth, expiryYear] =
          creditCard.expiration_date.split("/");
        const formattedExpiryYear =
          expiryYear.length === 2 ? `20${expiryYear}` : expiryYear;

        const paymentRequest = {
          advisorId: "",
          appointmentId: "",
          pricingPackageId: packageDetails?.id || "",
          discountCouponId: discountCouponDetails ? discountCouponDetails.id : null,
          cardDetails: {
            name: creditCard.card_holder_name,
            number: creditCard.card_number.replaceAll(" ", ""),
            expiryMonth: String(Number(expiryMonth)),
            expiryYear: String(Number(formattedExpiryYear)),
            cvc: creditCard.cvc,
            installment: selectedInstallment,
          },
        };

        try {
          const response = await paymentClient().post(
            "/payment/pay",
            paymentRequest
          );

          setLocalLoading(false);

          if (response.data.success) {
            navigate(
              `/payment-success-start-education/${Math.random()
                .toString(36)
                .substring(2, 15)}`
            );
          } else {
            setPaymentError(response.data.message || "Payment failed.");
          }
        } catch (error: any) {
          setLocalLoading(false);
          console.error("Payment error:", error);
          setPaymentError(
            error?.response?.data?.message ||
              "An error occurred during the payment."
          );
        }
      }
    } catch (error: any) {
      setLocalLoading(false);
      setPaymentError(
        error?.body?.detail || "An error occurred during the payment."
      );
    }
  };

  return (
    <>
      <img
        src={NameLogoBlue}
        alt="Logo"
        style={{ marginTop: "20px", marginBottom: "20px", cursor: "pointer" }}
        onClick={() => navigate("/home")}
      />
      <Divider style={{ width: "100%", border: "1px solid #E9EBED" }} />
      <CommonLoading loading={authLoading || localLoading} />
      <CommonAlert
        _type={"warning"}
        _open={paymentError !== ""}
        _onClose={() => setPaymentError("")}
        _message={paymentError}
      />
      <CommonAlert
        _type={"warning"}
        _open={discountCouponError !== ""}
        _onClose={() => setDiscountCouponError("")}
        _message={discountCouponError}
      />
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid
          item
          xs={4}
          sm={8}
          md={7}
          sx={{
            marginTop: "40px",
            marginBottom: "24px",
            display: "flex",
            justifyContent: "center",
            padding: { xs: "10px", sm: "10px", md: "0px" },
          }}
        >
          <Box
            component="div"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "80%",
              },
              textAlign: "start",
            }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: "24px",
                lineHeight: "32px",
                color: "#171725",
              }}
            >
              {phase === "address" ? "Fatura Adresi" : "Ödeme"}
            </Typography>
            {phase === "address" ? (
              <SubscriptionPaymentAddress
                setField={(e: IPackagePaymentAddress) => setInvoiceAddress(e)}
                values={invoiceAddress}
              />
            ) : (
              <SubscriptionPaymentCards
                price={Number(paidPrice)}
                setField={(e: INewCreditCard) => setCreditCard(e)}
                values={creditCard}
                selectSavedCard={setSelectedSavedCard}
                selectedCard={selectedSavedCard}
                card={creditCard}
                installmentData={installmentData}
                onCheckboxClicked={(e) => setSelectedInstallment(e)}
                selectedInstallmenOption={selectedInstallment}
                bank_name={bankNameForIcon}
                card_association={cardAssociationForIcon}
              />
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          sm={8}
          md={5}
          sx={{
            background: "#F9F9F9",
            minHeight: "90vh",
            display: "flex",
            justifyContent: "center",
            padding: { xs: "10px", sm: "10px", md: "0px" },
          }}
        >
          <Box
            component="div"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "70%",
              },
            }}
          >
            <SubscriptionPaymentSummary
              id={id ? id : ""}
              nameOfPackage={packageDetails?.name as string}
              onContunieDisabled={onContinueDisabled}
              onContunieTriggered={onContinueTriggered}
              activeDiscountCoupon={discountCouponDetails}
              paidPrice={paidPrice}
              currentPhase={phase}
              setPhase={setPhase}
              discountCouponCode={discountCouponCode}
              setDiscountCouponCode={setDiscountCouponCode}
              applyDiscountCoupon={applyDiscountCoupon}
              discountCouponError={discountCouponError}
              originalPrice={packageDetails?.price || '0'}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SubscriptionPayment;
