import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import SurveyAnswerBox from "./SurveyAnswerBox";
import ContentLinearProgressBar from "./ContentLinearProgressBar";
import { CompleteSurveyAnswer, SurveyQuestionAnswer } from "../client";
import parse from "html-react-parser";
import { ISurveyQuestionAnswer } from "../apiClient/models/survey";

interface Props {
  question_id: number;
  question: string;
  answers: Array<ISurveyQuestionAnswer>;
  onAnswerClicked: (question_id: number, answer_id: number) => void;
  localAnswers: Array<CompleteSurveyAnswer>;
  percentage:number
}

const SurveyQuestion: FC<Props> = ({
  question,
  answers,
  onAnswerClicked,
  question_id,
  localAnswers,
  percentage
}) => {
  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 12 }}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
      }}
    >
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        sx={{
          maxWidth:{xs:"90%", sm:"90%",md:"60%"},
        }}
      >
        <ContentLinearProgressBar percentage={percentage} />
      </Grid>
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        sx={{
          marginTop: "30px",
          maxWidth:{xs:"90%", sm:"90%",md:"80%"},
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "28px",
            color: "#171725",
          }}
        >
          {parse(question)}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "40px",
        }}
      >
        {answers.length > 0 &&
          answers.map((e, index) => {
            return (
              <SurveyAnswerBox
                width="60%"
                text={e.answerContent}
                isSelected={
                  localAnswers.find(
                    (k) =>
                      k.question_id == question_id && k.answer_id == e.answerId
                  )
                    ? true
                    : false
                }
                key={index}
                _onClick={() => onAnswerClicked(question_id, e.answerId)}
              />
            );
          })}
      </Grid>
    </Grid>
  );
};

export default SurveyQuestion;
