import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { authClient, userClient } from "../../../apiClient/apiClient";
import { UserState, LoginResponse, LogoutResponse, RegisterResponse, WrongResponse } from '../../../apiClient/models/user';

const initialState: UserState = {
  accessToken: localStorage.getItem('accessToken') || '',
  user: null,
};

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async ({ email, password }: { email: string; password: string }, thunkAPI) => {
    try {
      const response = await authClient().post<LoginResponse>('/login', { email, password });
      console.log("response token reduxtan geldim", response.data.data.accessToken)
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return thunkAPI.rejectWithValue(error.response.data);
      } else {
        return thunkAPI.rejectWithValue({ message: 'An error occurred' });
      }
    }
  }
);

export const registerUser = createAsyncThunk<
  RegisterResponse,
  { email: string; password: string; phone: null; registerDevicePlatform: string },
  { rejectValue: WrongResponse }
>(
  'user/registerUser',
  async ({ email, password, phone, registerDevicePlatform }, thunkAPI) => {
    try {
      const response = await userClient().post<RegisterResponse>('/user/register', { email, password, phone, registerDevicePlatform });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return thunkAPI.rejectWithValue(error.response.data as WrongResponse);
      } else {
        return thunkAPI.rejectWithValue({ statusCode: 500, error: 'Internal Server Error', message: 'An error occurred' });
      }
    }
  }
);

export const logoutUser = createAsyncThunk(
  'user/logoutUser',
  async (_, { rejectWithValue }) => {
    try {
      const response = await userClient().post<LogoutResponse>('/user/logout');
      localStorage.removeItem('accessToken');
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ message: 'An error occurred' });
      }
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
      localStorage.setItem('accessToken', action.payload);
    },
    setUser: (state, action: PayloadAction<UserState['user']>) => {
      state.user = action.payload;
    },
    toggleTeacherMode: (state) => {
      if (state.user) {
        state.user.isUserTeacher = !state.user.isUserTeacher;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action: PayloadAction<LoginResponse>) => {
        state.accessToken = action.payload.data.accessToken;
        state.user = {...action.payload.data.user, isUserTeacher: false}
        localStorage.setItem('accessToken', action.payload.data.accessToken);
      })
      .addCase(loginUser.rejected, (_state, action) => {
        console.error('Login failed:', action.payload);
      })
      .addCase(registerUser.fulfilled, (state, action: PayloadAction<RegisterResponse>) => {
        state.accessToken = action.payload.data.accessToken;
        state.user = action.payload.data.user;
        localStorage.setItem('accessToken', action.payload.data.accessToken);
      })
      .addCase(registerUser.rejected, (_state, action) => {
        if (action.payload) {
          console.error('Register failed:', action.payload.message);
        } else {
          console.error('Register failed');
        }
      })
      .addCase(logoutUser.fulfilled, (state, _action: PayloadAction<LogoutResponse>) => {
        state.user = null;
        state.accessToken = '';
        localStorage.removeItem('accessToken');
      })
      .addCase(logoutUser.rejected, (_state, action) => {
        console.error('Logout failed:', action.payload);
      });
  },
});

export const { setAccessToken, setUser, toggleTeacherMode } = userSlice.actions;
export default userSlice.reducer;
