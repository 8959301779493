import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BasicSurveyData, CompleteSurveyAnswer, SurveyDetail, UserService } from "../client";
import { ReduxStoreStatus } from "../utils/constants";
import { GetAllSurveys, GetSurvey } from "../apiClient/services";
import { ISurvey } from "../apiClient/models/survey";

export const getAllSurveys = createAsyncThunk(
    'survey/getAllSurveys',
    async() => {
        try {
            return await GetAllSurveys()
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

export const getSurvey = createAsyncThunk(
    'survey/getSurvey',
    async(payload:{survey_token:string}) => {
        try {
            return await GetSurvey(payload.survey_token)
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

interface IInitialState {
    loading:boolean;
    error:string;
    survey_items:{
        anket_Erisim_Anahtari:string,
        anket_Basligi:string
    }[];
    single_survey: ISurvey
}

const initialState:IInitialState = {
    loading:false,
    error:"",
    survey_items:[],
    single_survey:{} as ISurvey
}

export const surveySlice = createSlice({
    name:"survey",
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder.addCase(getAllSurveys.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(getAllSurveys.fulfilled, (state, action) => {
            state.survey_items = action.payload.data
            state.loading = false
        })
        builder.addCase(getAllSurveys.rejected, (state,action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
        builder.addCase(getSurvey.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(getSurvey.fulfilled, (state, action) => {
            state.single_survey = action.payload
            state.loading = false
        })
        builder.addCase(getSurvey.rejected, (state,action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
    }
})

export default surveySlice.reducer