import { Checkbox, Grid, Typography } from "@mui/material";
import { FC } from "react";
import {
  InstallmentTableChecked,
  InstallmentTableDefaultUnchecked,
} from "../assests";
import { InstallmentPrice } from "../apiClient/models/installment";

interface Props {
  options: InstallmentPrice[];
  selectedInstallmenOption?: number;
  onCheckboxClicked: (e: number) => void;
  price?: number;
}

const DynamicInstallmentTable: FC<Props> = ({
  options,
  selectedInstallmenOption,
  onCheckboxClicked,
}) => {
  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 12 }}
      style={{
        borderRadius: "16px",
        border: "1px solid #E9EBED",
        backgroundColor: "#F9F9F9",
      }}
    >
      <Grid
        item
        container
        xs={4}
        sm={8}
        md={12}
        style={{
          backgroundColor: "#F1F1F1",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
        }}
      >
        <Grid item xs={2} sm={4} md={6} style={{ padding: "9px 16px" }}>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "22px",
              color: "#78828A",
            }}
          >
            Taksit Sayısı
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sm={4}
          md={6}
          style={{ borderLeft: "1px solid #E3E9ED", padding: "9px 16px" }}
        >
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "22px",
              color: "#78828A",
            }}
          >
            Aylık Ödeme
          </Typography>
        </Grid>
      </Grid>
      {options.map((e) => (
        <Grid
          item
          container
          xs={4}
          sm={8}
          md={12}
          style={{ borderTop: "1px solid #E3E9ED" }}
          key={e.installmentNumber}
        >
          <Grid
            item
            xs={2}
            sm={4}
            md={6}
            style={{
              padding: "9px 16px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Checkbox
              icon={<img src={InstallmentTableDefaultUnchecked} alt="" />}
              checkedIcon={<img src={InstallmentTableChecked} alt="" />}
              style={{ padding: "0px", marginRight: "5px" }}
              onChange={() => onCheckboxClicked(e.installmentNumber)}
              checked={selectedInstallmenOption === e.installmentNumber}
            />
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "22px",
                color: "#78828A",
              }}
            >
              {e.installmentNumber === 1
                ? "Tek Çekim"
                : `${e.installmentNumber} Taksit`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sm={4}
            md={6}
            style={{ borderLeft: "1px solid #E3E9ED", padding: "9px 16px" }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "22px",
                color: "#78828A",
              }}
            >
              {e.installmentPrice.toFixed(2)} TL
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default DynamicInstallmentTable;
