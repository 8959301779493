import { FC, useEffect, useState } from "react";
import { Container, Grid, Typography } from "@mui/material";
import {
  CommonLoading,
  ParentSolutionDetailHeader,
  ParentSolutionContentProgressList,
  ParentSolutionDetailDescription,
  ParentSolutionVideoContentHeader,
  TextArticleHeader,
  VideoPlayer,
} from "../components";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { getParentSolution } from "../redux/parentSolutionSlice";
import { useNavigate } from "react-router-dom";
import { ArticleType, TrainingSetArticleItem, OpenAPI, ProgressLogItemType, ProgressLogNameType } from "../client";
import parse from "html-react-parser";
import { Buckets } from "../utils/constants";
import { getArticle } from "../redux/articleSlice";
import useGoogleReCaptchaV3 from "../utils/googleReCaptchaV3.ts/useGoogleReCaptchaV3";
import { getVideoUrl } from "../utils/commonFuncs";
import { addProgress } from "../redux/logSlice";
import { UseVideoPageTimeCounter } from "../hooks";
import { trainingSetDoesUserHasAccessToContent } from "../components/ParentSolutionContentProgressList";
import { openBecomePremiumModal } from "../redux/generalControllerSlice";
import { IArticle } from "../apiClient/models/article";

const ParentSolution: FC = () => {
  // const { executeReCaptcha } = useGoogleReCaptchaV3({ siteKey: `${process.env.REACT_APP_RECAPTCHA_KEY}`  });
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedContent, setselectedContent] =
    useState<IArticle | null>(null);
  const [videoUrlFetched, setVideoUrlFetched] = useState<string>("")

  const dispatch = useDispatch<AppDispatch>();
  const { single_parent_solution, loading, error } = useSelector(
    (state: RootState) => state.parentSolution
  );
  const {single_article, loading:article_loading} = useSelector((state:RootState) => state.article)
  const { access_token, profile } = useSelector((state: RootState) => state.auth);
  const [reCaptchaLoading, setReCaptchaLoading] = useState<boolean>(false)

  useEffect(() => {
    if (id != undefined || id != null) {
      dispatch(getParentSolution({ trainingSetId: id }));
    } else {
      navigate(-1);
    }
  }, []);

  const totalWatchTimeCalculator = (articles: IArticle[]) => {
    if (articles.length == 0) {
      return 0;
    }
    let total = 0;
    articles.forEach((element) => {
      total += element?.videoDurationInSeconds
        ? element.videoDurationInSeconds
        : 0;
    });
    return Math.floor(total / 60);
  };

  const contentEndCallBack = () => {
    try {
      const articlesDuplicated = single_parent_solution.articles
      const index = articlesDuplicated.findIndex(e => e.id == selectedContent?.id)
      if(trainingSetDoesUserHasAccessToContent(profile?.is_user_premium ? profile.is_user_premium : false, index+1)){
        if(articlesDuplicated.length - 1 >= index){
          selectNewContent(articlesDuplicated[index + 1])
        }
      }else {
        dispatch(openBecomePremiumModal())
      }

    } catch (error) {}
  }

  const selectNewContent = (item: IArticle) => {
    try {
      setselectedContent(item)
      dispatch(addProgress(
        {
          progressableType:"Article",
          progressableId: item.id.toString(),
          status:"watched"
        }
      ))

    } catch (error) {}
  }
  async function fetchURL() {
    setReCaptchaLoading(true)
    if(selectedContent?.type === ArticleType.VIDEO){
      // const token = await executeReCaptcha('LoadReCaptchaOnClick');
      const newUrl = await getVideoUrl(selectedContent.id.toString(), "token")
      setVideoUrlFetched(newUrl)
    }
    setReCaptchaLoading(false)
  }
  useEffect(() => {
    fetchURL()
  }, [selectedContent])

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <CommonLoading loading={loading || article_loading || reCaptchaLoading} />
      {!loading && Object.keys(single_parent_solution).length > 5 ? (
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          rowSpacing={3}
          columnSpacing={3}
        >
          {selectedContent == null ? (
            <Grid item xs={4} sm={8} md={12}>
              <ParentSolutionDetailHeader
                id={single_parent_solution.id.toString()}
                title={single_parent_solution.name}
                is_picture_uploaded={
                  single_parent_solution?.isPictureUploaded
                    ? single_parent_solution.isPictureUploaded
                    : false
                }
                watchedPercent={single_parent_solution.watchPercent}
              />
            </Grid>
          ) : selectedContent.type == ArticleType.VIDEO ? (
            <>
              <Grid item xs={4} sm={8} md={8}>
                <VideoPlayer
                  url={videoUrlFetched}
                  thumbnailUrl={`${Buckets.ArticleImageBucket}/${selectedContent.id}.jpg`}
                  contentEndCallBack={contentEndCallBack}
                />
              </Grid>
              <Grid item xs={4} sm={8} md={4}>
                <ParentSolutionDetailDescription
                  description={
                    single_parent_solution?.shortDescription
                      ? single_parent_solution.shortDescription
                      : ""
                  }
                  video_article_count={
                    single_parent_solution.articles.filter(
                      (e) => e.type == ArticleType.VIDEO
                    )?.length
                  }
                  text_article_count={
                    single_parent_solution.articles.filter(
                      (e) => e.type == ArticleType.TEXT
                    )?.length
                  }
                  watch_time_count={totalWatchTimeCalculator(
                    single_parent_solution.articles
                  )}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={4} sm={8} md={12}>
              <TextArticleHeader
                id={selectedContent.id.toString()}
                title={selectedContent.name}
                is_picture_uploaded={true}
              />
            </Grid>
          )}
          {selectedContent != null &&
          selectedContent?.type == ArticleType.VIDEO ? (
            <Grid item xs={4} sm={8} md={10}>
              <ParentSolutionVideoContentHeader
                contentId={selectedContent.id.toString()}
                title={selectedContent.name}
                parentSolutionName={single_parent_solution.name}
                parentSolutionId={single_parent_solution.id.toString()}
              />
            </Grid>
          ) : selectedContent != null &&
            selectedContent.type == ArticleType.TEXT && !article_loading ? (
            <Grid item xs={4} sm={8} md={12}>
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "33px",
                  color: "#000000",
                  textAlign: "start",
                }}
              >
                {parse(single_article.textContent ? single_article.textContent : "")}
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={4} sm={8} md={selectedContent == null ? 8 : 12}>
            <ParentSolutionContentProgressList
              items={single_parent_solution.articles}
              onContentSelected={(item: IArticle) =>
                selectNewContent(item)
              }
              parentSolutionId={single_parent_solution.id.toString()}
              currentSelectedItemId={
                selectedContent == null ? undefined : selectedContent.id.toString()
              }
            />
          </Grid>
          {selectedContent == null ? (
            <Grid
              item
              xs={false}
              sm={false}
              md={4}
              sx={{ display: { xs: "none", sm: "none", md: "block" } }}
            >
              <ParentSolutionDetailDescription
                description={
                  single_parent_solution?.shortDescription
                    ? single_parent_solution.shortDescription
                    : ""
                }
                video_article_count={
                  single_parent_solution.articles.filter(
                    (e) => e.type == ArticleType.VIDEO
                  )?.length
                }
                text_article_count={
                  single_parent_solution.articles.filter(
                    (e) => e.type == ArticleType.TEXT
                  )?.length
                }
                watch_time_count={totalWatchTimeCalculator(
                  single_parent_solution.articles
                )}
              />
            </Grid>
          ) : null}
        </Grid>
      ) : null}
    </Container>
  );
};

export default ParentSolution;
