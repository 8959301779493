import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ITrainingSetList } from "../apiClient/models/trainingSet";
import { GetAllArticles, GetAllTrainingSets } from "../apiClient/services";
import { IArticle } from "../apiClient/models/article";

export const getTrainingSetSearch = createAsyncThunk(
    'search/getTrainingSetSearch',
    async(payload:{query:string}) => {
        try {
            return await GetAllTrainingSets(5, 1, payload.query)
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

export const getArticleSearch = createAsyncThunk(
    'search/getArticleSearch',
    async(payload:{query:string}) => {
        try {
            return await GetAllArticles(10, 1, payload.query, "text")
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

interface IInitialState {
    loading:boolean
    error:string
    search_text:string
    trainingSetSearchItems: Array<ITrainingSetList>
    articleSearchItems: Array<IArticle>
}

const initialState: IInitialState = {
    loading:false,
    error:"",
    search_text:"",
    trainingSetSearchItems: [] as ITrainingSetList[],
    articleSearchItems: [] as IArticle[]
}

export const searchSlice = createSlice({
    name:"search",
    initialState,
    reducers: {
        setLocalSearchWord(state,action){
            state.search_text= action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTrainingSetSearch.pending, (state) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(getTrainingSetSearch.fulfilled, (state, action) => {
            state.trainingSetSearchItems = action.payload.data
            state.search_text = action.meta.arg.query
            state.loading = false
        })
        builder.addCase(getTrainingSetSearch.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })

        builder.addCase(getArticleSearch.pending, (state) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(getArticleSearch.fulfilled, (state, action) => {
            state.articleSearchItems = action.payload.data
            state.search_text = action.meta.arg.query
            state.loading = false
        })
        builder.addCase(getArticleSearch.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
        
    }
})

export const { setLocalSearchWord } = searchSlice.actions
export default searchSlice.reducer