import { FC } from "react";
import {
  ParentSchoolContentTick,
  PlayGreen,
  ParentSolutionContentLocked,
  ReadyToPlay,
  Lock,
} from "../assests";
import {
  StepConnector,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { stepConnectorClasses } from "@mui/material/StepConnector";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { doesUserHasAccessToContent } from "../utils/commonFuncs";
import { openBecomePremiumModal } from "../redux/generalControllerSlice";
import { IArticle } from "../apiClient/models/article";

interface Props {
  items: IArticle[];
  onContentSelected: (item: IArticle) => void;
  parentSolutionId: string;
  currentSelectedItemId?: string;
}

export const trainingSetDoesUserHasAccessToContent = (isUserPremium:boolean, index:number) => {
  try {
    if(isUserPremium){
      return true
    }else{
      if(index === 0 || index === 1){
        return true
      }else {
        return false
      }
    }
  } catch (error) {
    return false
  }
}

const ParentSolutionContentProgressList: FC<Props> = ({
  items = [],
  onContentSelected,
  parentSolutionId,
  currentSelectedItemId,
}) => {
  const { profile } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#DADADA",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#0EAD69",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      width: "1px",
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "",
    zIndex: 1,
    color: "#fff",
    width: 24,
    height: 24,
    display: "flex",
    borderRadius: "170%",
    justifyContent: "center",
    alignItems: "center",
  }));

  function ColorlibStepIcon(item: IArticle, index: number) {
    const icons: { [index: string]: React.ReactElement } = {
      watched: <img src={ParentSchoolContentTick} alt="" />,
      currentlyPlaying: <img src={PlayGreen} alt="" />,
      locked: <img src={ParentSolutionContentLocked} alt="" />,
      readyToPlay: <img src={ReadyToPlay} alt="" />,
    };

    let userHasAccess = doesUserHasAccessToContent(
      profile?.is_user_premium ? profile.is_user_premium : false,
      item?.showToFreePlan ? item.showToFreePlan : false
    );

    if(!userHasAccess) {
      return (
        <ColorlibStepIconRoot ownerState={{}}>
         {icons[String("locked")]}
        </ColorlibStepIconRoot>
      )
    } else {
      return (
        <ColorlibStepIconRoot ownerState={{}}>
          {
            icons[
              String(
                  currentSelectedItemId == item.id.toString()
                    ? "currentlyPlaying"
                    : item.isWatched ? "watched" : "readyToPlay"
              )
            ]
          }
        </ColorlibStepIconRoot>
      )
    }
  }

  const containerStyle = {
    backgroundColor: "#F9F9F9",
    borderRadius: "24px",
    padding: "25px 30px",
    display: "block",
  };

  const onContentClicked = (item: IArticle, index:number) => {
    const userHasAccess = trainingSetDoesUserHasAccessToContent(profile?.is_user_premium ? profile.is_user_premium : false, index)
    if (userHasAccess) {
      onContentSelected(item);
    } else {
      dispatch(openBecomePremiumModal());
    }
  };

  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }} style={containerStyle}>
      <Typography
        style={{
          textAlign: "start",
          fontFamily: "Montserrat",
          fontSize: "22px",
          fontWeight: "700",
          lineHeight: "30px",
          color: "#0D0C0F",
          marginBottom: "30px",
        }}
      >
        Ders İçeriği
      </Typography>
      <Stepper
        activeStep={0}
        orientation="vertical"
        connector={<ColorlibConnector />}
      >
        {items.map((item, index) => (
          <Step style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}} key={index}>
            <StepLabel StepIconComponent={() => ColorlibStepIcon(item, index)}>
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "28px",
                  color: "#0D0C0F",
                  cursor: "pointer",
                }}
                onClick={() => onContentClicked(item,index)}
              >
                {index + 1 + "- " + item.name}
              </Typography>
            </StepLabel>
            {trainingSetDoesUserHasAccessToContent(
              profile?.is_user_premium ? profile.is_user_premium : false,
              index
            ) ? null : (
              <img src={Lock} alt="" />
            )}
          </Step>
        ))}
      </Stepper>
    </Grid>
  );
};

export default ParentSolutionContentProgressList;
