import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Menu,
  MenuItem,
  Fade,
  Badge,
} from "@mui/material";
import { ArrowDown, Hamburger, LogOut, NameLogoBlue, NoficationBell } from "../assests";
import {
  Buckets,
  ContentMenuItems,
  ReduxStoreStatus,
  SubMenuItems,
} from "../utils/constants";
import LayoutSearch from "./LayoutSearch";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { logOutFavs } from "../redux/favoriteSlice";
import {
  getSurveyLogs,
  getUserProgressStatuses,
  logOutLogs,
} from "../redux/logSlice";
import { getUserProfile, setCredentialsForSebit } from "../redux/authSlice";
import { Typography } from "@mui/material";
import { getNameTextLogo } from "../utils/commonFuncs";
import { OpenAPI } from "../client";
import LogOutButton from "./LogOutButton";
import { logOut } from "../redux/authSlice";
import { getAvailablePurchasePackages, getGiftDiscountRate, getMySavedCards } from "../redux/purchaseSlice";
import { getAllNotifications } from "../redux/notificationSlice";

const drawerWidth = 250;

interface Props {
  window?: () => Window;
  children: React.ReactNode;
}

const Layout = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { status } = useSelector((state: RootState) => state.favorite);
  const { access_token, profile, loading, refresh_token } = useSelector(
    (state: RootState) => state.auth
  );

  const { status: logStatus } = useSelector((state: RootState) => state.log);
  const { notifications } = useSelector((state:RootState) => state.notification)

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  let navigate = useNavigate();
  const { pathname } = useLocation();
  
  //FOR GIFT PAGE REDIRECT FROM MOBILE TO WEB:
    const [searchParams, setSearchParams] = useSearchParams();

  //--------

  //FOR RIGHT CORNER LOGOUT COMPONENT !
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openLogOutComp = Boolean(anchorEl);
  const handleClickRightDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseRightDropdown = () => {
    setAnchorEl(null);
  };
  //--------

  useEffect(() => {
    if(access_token != "" && access_token.length > 10) {
      if (OpenAPI.TOKEN == undefined && access_token != "") {
        OpenAPI.TOKEN = access_token;
      }
  
      if (logStatus == ReduxStoreStatus.default) {
        dispatch(getUserProgressStatuses());
        dispatch(getSurveyLogs());
      }
  
      if (Object.keys(profile).length == 0) {
        dispatch(getUserProfile());
      }

      dispatch(getAllNotifications({last_notification_id:undefined}))
    }
  }, [access_token]);

  useEffect(() => {
    if (access_token === "") {
      if(pathname == '/gift-to-friend'){
        if(searchParams.get("token") && searchParams.get("token")  != null && searchParams.get("refresh_token") && searchParams.get("refresh_token") != null){
          dispatch(setCredentialsForSebit({access_token:searchParams.get("token"), refresh_token:searchParams.get("refresh_token")}))
          dispatch(getAvailablePurchasePackages());
          dispatch(getMySavedCards());
          setTimeout(() => navigate('/gift-to-friend') ,500);
        }else {
          navigate('/login')
        }
      }else{
        navigate("/login");
      }
    }
  }, [window?.name, localStorage.length]);

  useEffect(() => {
    if (
      !loading &&
      access_token != "" &&
      Object.keys(profile).length > 5 &&
      (profile.name == null ||
        profile.name == "" ||
        profile.name.trimEnd().trimStart().split(" ").length < 2)
    ) {
      navigate("/register-flow");
    }
  }, [profile, access_token]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  async function logOutTrigger() {
    dispatch(logOut());
    dispatch(logOutFavs());
    dispatch(logOutLogs());
  }

  const drawer = (
    <div>
      <Toolbar style={{ backgroundColor: "#ffffff" }}>
        <img
          src={NameLogoBlue}
          alt=""
          style={{ height: "100%", width: "auto", objectFit: "contain", cursor:"pointer" }}
          onClick={() => navigate("/home")}
        />
      </Toolbar>
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText>
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#9CA4AB",
                }}
              >
                İÇERİKLER
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        {ContentMenuItems.map((item, index) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton
              onClick={() => navigate(item.url)}
              style={{
                borderLeft: pathname == item.url ? "4px solid #2574FF" : "",
              }}
            >
              <img
                style={{ color: "#78828A", marginRight: "14px" }}
                src={pathname == item.url ? item.iconSelected : item.icon}
              />
              <ListItemText>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: pathname == item.url ? "#171725" : "#78828A",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {item.name}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#9CA4AB",
              }}
            >
              MENÜ
            </Typography>
          </ListItemButton>
        </ListItem>
        {SubMenuItems.map((item, index) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton
              onClick={() => navigate(item.url)}
              style={{
                borderLeft: pathname == item.url ? "4px solid #2574FF" : "",
              }}
            >
              <img
                style={{ color: "#78828A", marginRight: "14px" }}
                src={pathname == item.url ? item.iconSelected : item.icon}
              />
              <ListItemText>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: pathname == item.url ? "#171725" : "#78828A",
                  }}
                >
                  {item.name}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "#FFFFFF",
          boxShadow: "none",
        }}
      >
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <img src={Hamburger} />
          </IconButton>
          <LayoutSearch />
          <div
            style={{
              marginRight: "5%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          >
            <Badge badgeContent={notifications.user_notifications.filter((e) => !e.is_readed).length} color="error" style={{marginRight:"15px", cursor:"pointer"}}>
            <img src={NoficationBell} onClick={() => navigate("/notifications")} />

            </Badge>
            {profile.is_profile_picture_uploaded ? (
              <img
                src={
                  profile?.is_profile_picture_uploaded
                    ? `${Buckets.UserProfilePictureBucket}/${profile?.id}.jpg`
                    : ""
                }
                alt=""
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <div
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  color: "black",
                  fontSize: "21px",
                  border: "1px solid #E9EBED",
                  textAlign: "center",
                }}
              >
                {getNameTextLogo(profile?.name)}
              </div>
            )}
            <img
              src={ArrowDown}
              alt=""
              style={{ marginLeft: "7px", cursor: "pointer" }}
              id="fade-button"
              aria-controls={openLogOutComp ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openLogOutComp ? "true" : undefined}
              onClick={handleClickRightDropdown}
            />
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={openLogOutComp}
              onClose={handleCloseRightDropdown}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={() => logOutTrigger()}>
                <img src={LogOut} alt="" />
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "15px",
                    lineHeight: "24px",
                    color: "#FA4169",
                    marginLeft: "10px",
                  }}
                >
                  Çıkış Yap
                </Typography>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              border: "none",
              backgroundColor: "#F9F9F9",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ width: "100%" }}>
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
};

export default Layout;
