import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetParentSchoolModuleResponse, ParentSchoolModuleModel, UserService } from "../client";
import { IParentSchool } from "../apiClient/models/parentSchool";
import { GetAllParentSchools } from "../apiClient/services";

export const getAllParentSchoolContent = createAsyncThunk(
    'parentSchool/getAllParentSchoolContent',
    async(payload:{perPage:number, page:number, name:string}) => {
        try {
            const { perPage, page, name } = payload
            return await GetAllParentSchools(perPage, page, name)
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

interface IInitialState {
    loading:boolean;
    error:string;
    parentSchool: {
        data: IParentSchool[],
        total:number,
        currentPage:number
    }
}

const initialState:IInitialState = {
    loading:false,
    error:"",
    parentSchool:{
        data: [] as IParentSchool[],
        total:0,
        currentPage:1
    }
}


export const parentSchoolSlice = createSlice({
    name:"parentSchool",
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder.addCase(getAllParentSchoolContent.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getAllParentSchoolContent.fulfilled, (state,action) => {
            if(state.parentSchool.data.length == 0) {
                state.parentSchool.data = action.payload.data
            } else {
                state.parentSchool.data = [...state.parentSchool.data, ...action.payload.data]
            }

            state.parentSchool.total = action.payload.total
            state.parentSchool.currentPage = action.meta.arg.page
            state.loading = false;
            state.error = ""
        })
        builder.addCase(getAllParentSchoolContent.rejected, (state,action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
    }
})

export default parentSchoolSlice.reducer