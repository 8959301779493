import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import DetailBackButton from "./DetailBackButton";
import DetailShareButton from "./DetailShareButton";
import DetailFavoriteButton from "./DetailFavoriteButton";
import EvaluateContentButton from "./EvaluateContentButton";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../redux/store/store";
import { Buckets } from "../utils/constants";
import { FavoriteType, ProgressStatusType, UserFeedBackContentType } from "../client";
import {
  addItemToFavorite,
  removeItemFromFavorite,
} from "../redux/favoriteSlice";
import ContentLinearProgressBar from "./ContentLinearProgressBar";
import { openContentEvaluateModal, openShareContentModal } from "../redux/generalControllerSlice";

interface Props {
  id: string;
  title: string;
  is_picture_uploaded: boolean;
  watchedPercent:number;
}

const ParentSolutionDetailHeader: FC<Props> = ({
  id = "",
  title = "",
  is_picture_uploaded = false,
  watchedPercent = 0
}) => {
  const navigate = useNavigate();
  const { favorite_items } = useSelector((state: RootState) => state.favorite);
  const { progress_status_items } = useSelector(
    (state: RootState) => state.log
  );
  const dispatch = useDispatch<AppDispatch>();

  const filtered_logs = progress_status_items.filter(
    (k) =>
      k.progress_status_type == ProgressStatusType.TRAINING_SET &&
      k.item_id == id
  );

  const containerStyle = {
    background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.74) 100%), url(${
      is_picture_uploaded ? `${Buckets.ParentSolutionBucket}/${id}.jpg` : ""
    })`,
    padding: "30px",
    borderRadius: "24px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const addContentToFavorite = () => {
    try {
      dispatch(
        addItemToFavorite({
          id_of_favorite: id,
          name_of_favorite: title,
          favourite_type: FavoriteType.TRAINING_SET,
        })
      );
    } catch (error) {}
  };

  const removeContentFromFavorite = () => {
    try {
      dispatch(removeItemFromFavorite({ favorite_item_id: id }));
    } catch (error) {}
  };

  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }} style={containerStyle}>
      <Grid item xs={4} sm={8} md={6}>
        <DetailBackButton onClick={() => navigate(-1)} />
      </Grid>
      <Grid
        item
        xs={false}
        sm={false}
        md={6}
        sx={{
          display: { xs: "none", sm: "none", md: "flex" },
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <DetailShareButton
          onClick={() => dispatch(openShareContentModal())}
          customCSS={{ marginRight: "5px" }}
        />
        <DetailFavoriteButton
          isLiked={favorite_items.some((e) => {
            return e.favourite_type == FavoriteType.TRAINING_SET && e.id == id;
          })}
          onClick={
            favorite_items.some((e) => {
              return (
                e.favourite_type == FavoriteType.TRAINING_SET && e.id == id
              );
            })
              ? () => removeContentFromFavorite()
              : () => addContentToFavorite()
          }
          customCSS={{ marginRight: "5px" }}
        />
        <EvaluateContentButton
          onClick={() => dispatch(openContentEvaluateModal({content_id:id ,content_type:UserFeedBackContentType.TRAININGSET}))}
          text="İçeriği Değerlendir"
        />
      </Grid>
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        sx={{
          maxWidth: { xs: "100%", sm: "100%", md: "85%" },
          textAlign: "start",
          marginTop: "15%",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "40px",
            lineHeight: "48px",
            color: "#FFFFFF",
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        sm={8}
        md={6}
        style={{ marginTop: "4%", textAlign: "start" }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "28px",
            color: "#FFFFFF",
            marginBottom: "10px",
          }}
        >
          %
          {watchedPercent}
          tamamlandı
        </Typography>
        <ContentLinearProgressBar
          percentage={
            watchedPercent
          }
        />
      </Grid>
    </Grid>
  );
};

export default ParentSolutionDetailHeader;
