import { AxiosResponse } from "axios";
import { contentClient } from "../apiClient";
import { IArticle, IArticles } from "../models/article";

export const GetAllArticles = async(perPage:number = 30, page:number = 1, name:string = "", type?: string):Promise<IArticles> => {
    try {
        const response: AxiosResponse<IArticles> = await contentClient().get('/article', {
            params: {
                page,
                perPage,
                name,
                type
            },
            });
            return response.data;
    } catch (error) {
        console.error('Error fetching articles:', error);
        throw new Error('Failed to fetch articles');
    }
}

export const GetArticle = async(id:number):Promise<IArticle> => {
    try {
        const response: AxiosResponse<{
            data:IArticle,
            code:string,
            message:string,
            success:boolean
        }> = await contentClient().get(`/article/${id}`);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching articles:', error);
        throw new Error('Failed to fetch articles');
    }
}