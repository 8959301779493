import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BulletinModel, UserService } from "../client";
import { GetAllBulletins, GetBulletin } from "../apiClient/services";
import { IBulletin } from "../apiClient/models/bulletin";

export const getBulettins = createAsyncThunk(
    'bulettin/getBulettins',
    async (payload:{perPage:number, page:number, title:string}) => {
        try {
            return await GetAllBulletins(payload.perPage, payload.page, payload.title)
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

export const getBulletin = createAsyncThunk(
    'bulettin/getBulletin',
    async (payload:{bulletinId:string}) => {
        try {
            return await GetBulletin(Number(payload.bulletinId))
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

interface IInitialState {
    loading:boolean
    error:string | null
    bulletin: Array<IBulletin>
    singleBulletin: IBulletin
    total: number
    currentPage:number
}

const initialState: IInitialState = {
    loading:false,
    error:null,
    bulletin:[] as IBulletin[],
    singleBulletin:{} as IBulletin,
    total:0,
    currentPage:1
}

export const bulettinSlice = createSlice({
    name:"bulettin",
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder.addCase(getBulettins.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(getBulettins.fulfilled, (state, action) => {
            state.bulletin = action.payload.data
            state.loading = false
        })
        builder.addCase(getBulettins.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
        builder.addCase(getBulletin.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(getBulletin.fulfilled, (state, action) => {
            state.singleBulletin = action.payload
            state.loading = false
        })
        builder.addCase(getBulletin.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
    }
})

export default bulettinSlice.reducer