import { AxiosResponse } from "axios";
import { IBulletin, IBulletins } from "../models/bulletin";
import { contentClient } from "../apiClient";

export const GetAllBulletins = async(perPage:number = 30, page:number = 1, title:string = ""):Promise<IBulletins<IBulletin[]>> => {
    try {
        const response: AxiosResponse<IBulletins<IBulletin[]>> = await contentClient().get('/bulletin', {
            params: {
                page,
                perPage,
                title
            },
            });
            return response.data;
    } catch (error) {
        console.error('Error fetching bulletins:', error);
        throw new Error('Failed to fetch bulletins');
    }
}

export const GetBulletin = async(id:number):Promise<IBulletin> => {
    try {
        const response: AxiosResponse<{data:IBulletin}> = await contentClient().get(`/bulletin/${id}`);
        return response.data.data
    } catch (error) {
        console.error('Error fetching bulletin:', error);
        throw new Error('Failed to fetch bulletin');
    }
}