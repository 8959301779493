import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { ReduxStoreStatus } from "../utils/constants";
import { ArticleType, BasicSurveyLog, CompleteSurveyAnswer, CreateProgressLog, ProgressLogNameType, ProgressStatusItem, UserService } from "../client";
import { generateLocalProgressStatusItem } from "../utils/commonFuncs";
import { AddProgressLog } from "../apiClient/services";

export const getUserProgressStatuses = createAsyncThunk(
    'log/getUserProgressStatuses',
    async () => {
        try {
            return await UserService.getProgressStatusesUserProgressStatusesGet()
        } catch (error: any) {
            throw new Error(error?.message)
        }
    }
)

export const getSurveyLogs = createAsyncThunk(
    'log/getSurveyLogs',
    async () => {
        try {
            return await UserService.getSurveyLogsWithNoPaginationUserSurveyLogsWithNoPaginationGet()
        } catch (error: any) {
            throw new Error(error?.message)
        }
    }
)

export const submitSurvey = createAsyncThunk(
    'survey/submitSurvey',
    async (payload: { child_id?: string, survey_token: string, answers: Array<CompleteSurveyAnswer>, survey_title: string, survey_description: string, survey_id: number, temp_id: string, completed_at: string }) => {
        try {
            return await UserService.completeSurveyUserCompleteSurveyPost({ child_id: payload.child_id, survey_token: payload.survey_token, answers: payload.answers })
        } catch (error: any) {
            throw new Error(error?.message)
        }
    }
)

export const addProgress = createAsyncThunk(
    'survey/addProgress',
    async (payload: {
        progressableType: string,
        progressableId: string,
        status: string
    }) => {
        try {
            return await AddProgressLog(payload.progressableType, payload.progressableId, payload.status)
        } catch (error: any) {
            throw new Error(error.message)
        }
    }
)

export interface NewProgressLog extends CreateProgressLog {
    total_items_count?: number
    item_name?: string
    module_name?: string
    article_type: ArticleType
}

interface IInitialState {
    loading: boolean,
    error: string,
    status: ReduxStoreStatus,
    progress_status_items: ProgressStatusItem[]
    survey_logs: Array<BasicSurveyLog>
}

const initialState: IInitialState = {
    loading: false,
    error: "",
    status: ReduxStoreStatus.default,
    progress_status_items: [] as ProgressStatusItem[],
    survey_logs: [] as BasicSurveyLog[]
}

export const logSlice = createSlice({
    name: "log",
    initialState,
    reducers: {
        logOutLogs(state) {
            state.status = ReduxStoreStatus.default
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUserProgressStatuses.pending, (state) => {
            state.loading = true
            state.status = ReduxStoreStatus.default
            state.error = ""
        })
        builder.addCase(getUserProgressStatuses.fulfilled, (state, action) => {
            state.loading = false
            state.error = ""
            state.progress_status_items = action.payload
            state.status = ReduxStoreStatus.fetched
        })
        builder.addCase(getUserProgressStatuses.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ""
            state.status = ReduxStoreStatus.default
        })
        builder.addCase(getSurveyLogs.pending, (state) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(getSurveyLogs.fulfilled, (state, action) => {
            state.survey_logs = action.payload.survey_logs
            state.loading = false
            state.error = ""
        })
        builder.addCase(getSurveyLogs.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ""
        })
        builder.addCase(submitSurvey.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(submitSurvey.fulfilled, (state, action) => {
            const currentDate = new Date()
            const newSurveyLog = {
                id: action.meta.arg.temp_id,
                completed_at: action.meta.arg.completed_at,
                result_title: action.payload.title as string,
                result_content: action.payload.content as string,
                survey_id: action.meta.arg.survey_id,
                survey_title: action.meta.arg.survey_title,
                survey_description: action.meta.arg.survey_description,
                survey_token: action.meta.arg.survey_token,
                child_id: action.meta.arg.child_id
            } as BasicSurveyLog
            state.survey_logs.unshift(newSurveyLog)
            state.loading = false
        })
        builder.addCase(submitSurvey.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
        builder.addCase(addProgress.pending, (state, { meta }) => {
            const logItem = meta.arg
            //TODO::
            // state.progress_status_items = generateLocalProgressStatusItem(logItem, current(state.progress_status_items))
        })
        builder.addCase(addProgress.fulfilled, (state, action) => {
            // console.log("==>",current(state.progress_status_items))
        })
        builder.addCase(addProgress.rejected, (state, action) => {

        })
    }
})

export const { logOutLogs } = logSlice.actions
export default logSlice.reducer