const ENV = process.env.REACT_APP_ENV || 'production';

const Endpoints = (env: string = ENV) => {
    const isTestEnv = env === 'test';

    return {
        BASE_URL: `https://backend${isTestEnv ? '-test' : ''}.parentwiser.com`,
        AUTH_URL: `https://auth${isTestEnv ? '-test' : ''}.parentwiser.com`,
        APPOINTMENT_URL: `https://appointment${isTestEnv ? '-test' : ''}.parentwiser.com`,
        REPORT_URL: `https://report${isTestEnv ? '-test' : ''}.parentwiser.com`,
        USER_URL: `https://user${isTestEnv ? '-test' : ''}.parentwiser.com`,
        NOTIFICATION_URL: `https://notification${isTestEnv ? '-test' : ''}.parentwiser.com`,
        SURVEY_URL: 'https://user.parentwiser.com',
        PAYMENT_URL:`https://payment${isTestEnv ? '-test' : ''}.parentwiser.com`,
        CONTENT_URL:`https://content${isTestEnv ? '-test' : ''}.parentwiser.com`,
        CHAT_BOT_URL:`https://chatbot${isTestEnv ? '-test' : ''}.parentwiser.com`,
    } as const;
};

type EndpointKeys = keyof ReturnType<typeof Endpoints>;

export const getEndpointUrl = (service: EndpointKeys) => {
    const endpoints = Endpoints();
    return endpoints[service];
};
