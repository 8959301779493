import { FC, useEffect, useState } from "react";
import { Container, Grid, Typography } from "@mui/material";
import {
  CommonLoading,
  HeadingTitle,
  HomeArticleSlider,
  TextArticleHeader,
} from "../components";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { getArticle } from "../redux/articleSlice";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { ISuggestedArticle } from "../utils/commonModels";
import { shuffleAndGetContent } from "../utils/commonFuncs";
import { ArticleType, OpenAPI, ProgressLogItemType, ProgressLogNameType } from "../client";
import { addProgress } from "../redux/logSlice";

const Article: FC = () => {
  let { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { single_article, loading, articles } = useSelector(
    (state: RootState) => state.article
  );

  const [suggested, setSuggested] = useState<Array<ISuggestedArticle>>(
    [] as ISuggestedArticle[]
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (id != undefined || id != null) {
      dispatch(getArticle({ articleId: id }));
      dispatch(addProgress({
        progressableType:"Article",
        progressableId:id.toString(),
        status:"watched"
      }))
    } else {
      navigate(-1);
    }
  }, [id]);

  useEffect(() => {
    if (articles.data.length > 0) {
      setSuggested(
        shuffleAndGetContent(articles.data, 5).map((e) => {
          return {
            id: e.id,
            title: e.name,
            is_picture_uploaded: e.isPictureUploaded
              ? e.isPictureUploaded
              : false,
          };
        })
      );
    }
  }, []);
  console.log(single_article)
  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <CommonLoading loading={loading} />
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={8} md={12}>
          {!loading && Object.keys(single_article).length > 4 ? (
            <TextArticleHeader
              id={single_article.id.toString()}
              title={single_article.name}
              is_picture_uploaded={
                single_article?.isPictureUploaded
                  ? single_article.isPictureUploaded
                  : false
              }
            />
          ) : null}
        </Grid>
        <Grid
          item
          xs={4}
          sm={8}
          md={12}
          style={{ marginTop: "30px", marginBottom: "40px" }}
        >
          {!loading && Object.keys(single_article).length > 4 ? (
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "33px",
                color: "#000000",
                textAlign: "start",
              }}
            >
              {parse(single_article.textContent as string)}
            </Typography>
          ) : (
            <Typography></Typography>
          )}
        </Grid>
        {suggested.length > 0 ? (
          <Grid item xs={4} sm={8} md={12} style={{ textAlign: "start" }}>
            <HeadingTitle title="Önerilen Makaleler" />
            <HomeArticleSlider items={suggested} />
          </Grid>
        ) : null}
      </Grid>
    </Container>
  );
};

export default Article;
