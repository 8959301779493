import { Container, Grid } from "@mui/material";
import { FC, useEffect } from "react";
import {
  CommonLoading,
  FavoriteParentSchoolArticleSlider,
  FavoriteParentSolutionSlider,
  HeadingTitle,
  HomeArticleSlider,
} from "../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { ReduxStoreStatus } from "../utils/constants";
import { getAllUserFavorites } from "../redux/favoriteSlice";
import { FavoriteType, OpenAPI } from "../client";
import { NoFavoriteItem } from "../components";

const Favorites: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { favorite_items, loading, status } = useSelector(
    (state: RootState) => state.favorite
  );
  const { access_token } = useSelector((state: RootState) => state.auth);
    
  useEffect(() => {
    if (OpenAPI.TOKEN == undefined && access_token != "") {
      OpenAPI.TOKEN = access_token;
    }
    if (status == ReduxStoreStatus.default) {
      dispatch(getAllUserFavorites());
    }
  }, []);

  return (
    <Container
      maxWidth="xl"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <CommonLoading loading={loading} />
      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
        {!loading && favorite_items.length === 0 && (
          <Grid
            item
            xs={4}
            sm={8}
            md={4}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <HeadingTitle title="Favori İçerikleriniz" _marginBottom="32px" />
            <NoFavoriteItem />
          </Grid>
        )}
        {!loading &&
          favorite_items.filter(
            (e) => e.favourite_type == FavoriteType.TRAINING_SET
          ).length > 0 && (
            <Grid
              item
              xs={4}
              sm={8}
              md={12}
              style={{ textAlign: "start", width: "0px" }}
            >
              <HeadingTitle title="Ebeveyen Çözümleri" _marginBottom="32px" />
              <FavoriteParentSolutionSlider
                items={favorite_items
                  .filter((e) => e.favourite_type == FavoriteType.TRAINING_SET)
                  .map((e) => {
                    return {
                      id: e.id.toString(),
                      title: e.name_of_favorite,
                    };
                  })}
              />
            </Grid>
          )}
        {!loading &&
          favorite_items.filter((e) => e.favourite_type == FavoriteType.ARTICLE)
            .length > 0 && (
            <Grid
              item
              xs={4}
              sm={8}
              md={12}
              style={{ textAlign: "start", width: "0px"}}
            >
              <HeadingTitle title="Makaleler" _marginBottom="32px" />
              <HomeArticleSlider
                items={favorite_items
                  .filter((e) => e.favourite_type == FavoriteType.ARTICLE)
                  .map((e) => {
                    return {
                      id: Number(e.id),
                      title: e.name_of_favorite,
                      is_picture_uploaded: true,
                    };
                  })}
              />
            </Grid>
          )}
          {!loading &&
          favorite_items.filter((e) => e.favourite_type == FavoriteType.PARENT_SCHOOL_MODULE)
            .length > 0 && (
            <Grid
              item
              xs={4}
              sm={8}
              md={12}
              style={{ textAlign: "start", width: "0px"}}
            >
              <HeadingTitle title="Anne Baba Okulu" _marginBottom="32px" />
              <FavoriteParentSchoolArticleSlider
                items={favorite_items
                  .filter((e) => e.favourite_type == FavoriteType.PARENT_SCHOOL_MODULE)
                  .map((e) => {
                    return {
                      id: e.id.toString(),
                      title: e.name_of_favorite,
                      is_picture_uploaded: true,
                    };
                  })}
              />
            </Grid>
          )}
      </Grid>
    </Container>
  );
};

export default Favorites;
