import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserService, SearchService,FullTextSearchModel, ArticleModel } from "../client";
import { IArticle } from "../apiClient/models/article";
import { GetAllArticles, GetArticle } from "../apiClient/services";

export const getFilteredArticles = createAsyncThunk(
    'article/getFilteredArticles',
    async(payload:{perPage:number, page:number, name:string, type?:string}) => {
        try {
            const { perPage, page, name, type } = payload
            return await GetAllArticles(perPage, page, name, type)
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

export const getArticle = createAsyncThunk(
    'article/getArticle',
    async (payload:{articleId:string}) => {
        try {
            return await GetArticle(Number(payload.articleId))
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

interface IInitialState {
    articles: {
        data: IArticle[],
        total: number,
        currentPage:number
    }
    loading: boolean;
    error: string;
    suggested_articles:FullTextSearchModel[];
    single_article:IArticle
}

const initialState: IInitialState = {
    articles: {
        data: [] as IArticle[],
        total: 0,
        currentPage:1
    },
    loading: false,
    error: "",
    suggested_articles:[],
    single_article:{} as IArticle
}

export const articleSlice = createSlice({
    name: "article",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getFilteredArticles.pending, (state) => {
            state.loading = true;
            state.error = ''
        })
        builder.addCase(getFilteredArticles.fulfilled, (state, action) => {
            state.articles.data = action.payload.data

            state.articles.currentPage = action.meta.arg.page
            state.articles.total = action.payload.total
            state.loading = false;
            state.error = ''
        });
        builder.addCase(getFilteredArticles.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
        builder.addCase(getArticle.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(getArticle.fulfilled, (state, action) => {
            console.log(action.payload)
            state.single_article = action.payload
            state.loading = false
        })
        builder.addCase(getArticle.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
    }
})

export default articleSlice.reducer