import { AxiosResponse } from "axios";
import { contentClient } from "../apiClient";


export const AddProgressLog = async(
    progressableType:string, //TrainingSet, 
    progressableId:string,
    status:string //started, watched
):Promise<any> => {
    try {
        const response: AxiosResponse<any> = await contentClient().post('/progressLog', {
            progressableType,
            progressableId,
            status
        })
        return response.data
    } catch (error) {
        console.error('Error creating progress log:', error);
        throw new Error('Failed to create progress log'); 
    }
}