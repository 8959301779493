import { AxiosResponse } from "axios";
import { contentClient } from "../apiClient";
import { ILearnWithQuestions } from "../models/learnWithQuestion";

export const GetAllQuestions = async (perPage: number = 30, page: number = 1): Promise<ILearnWithQuestions> => {
    try {
        const response: AxiosResponse<ILearnWithQuestions> = await contentClient().get('/learnwithquestion', {
            params: {
                page,
                perPage,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching questions:', error);
        throw new Error('Failed to fetch question');
    }
}

export const SubmitLearnWithQuestionAnswer = async(id:string, answer:string):Promise<boolean> => {
    try {
        const response: AxiosResponse<{data:any, success:boolean}> = await contentClient().post('/learnwithquestion/submit', {
            id,
            answer
        })

        return response.data.success
    } catch (error) {
        console.error('Error submit questions answer:', error);
        throw new Error('Failed to submit question answer');
    }
}