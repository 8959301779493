import { FC, useState } from "react";
import { Grid, Tabs, Tab, Typography } from "@mui/material";
import CommonLabel from "./CommonLabel";
import CommonTextField from "./CommonTextField";
import CommonMaskedInput from "./CommonMaskedInput";
import { INewCreditCard, ISelectCardForPayment } from "../utils/commonModels";
import { InstallmentData } from "../apiClient/models/installment";
import PaymentCardVirtual from "./PaymentCardVirtual";
import DynamicInstallmentTable from "./DynamicInstallmentTable";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid
          container
          spacing={3}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ textAlign: "start" }}
        >
          {children}
        </Grid>
      )}
    </div>
  );
}

interface Props {
  setField: (e: INewCreditCard) => void;
  values: INewCreditCard;
  selectSavedCard: (e: ISelectCardForPayment) => void;
  selectedCard: ISelectCardForPayment;
  card: INewCreditCard;
  installmentData: InstallmentData | null;
  selectedInstallmenOption?: number;
  onCheckboxClicked: (e: number) => void;
  bank_name: string;
  card_association: string;
  price?: number;
}

const SubscriptionPaymentCards: FC<Props> = ({
  setField,
  values,
  card,
  installmentData,
  selectedInstallmenOption,
  onCheckboxClicked,
  bank_name,
  card_association,
  price,
}) => {
  const [value, setValue] = useState<number>(0);

  const tabNamesConstant = [
    {
      name: "Yeni Kart",
      value: 0,
    },
  ];

  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 12 }}
      spacing={3}
      style={{ marginTop: "12px" }}
    >
      <Grid item xs={4} sm={8} md={12}>
        <Tabs
          orientation="horizontal"
          value={value}
          onChange={(event: React.SyntheticEvent, newValue: number) =>
            setValue(newValue)
          }
          sx={{
            "& .MuiTabs-indicator": { border: "1px solid #2574FF" },
            width: "100%",
            alignItems: "start",
          }}
        >
          {tabNamesConstant.map((item, index) => {
            return (
              <Tab
                key={index}
                disableRipple
                {...a11yProps(item.value)}
                style={{ marginRight: "20px" }}
                label={
                  <Typography
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: value === item.value ? "700" : "600",
                      fontSize: "18px",
                      lineHeight: "26px",
                      color: value === item.value ? "#171725" : "#78828A",
                      textTransform: "none",
                    }}
                  >
                    {item.name}
                  </Typography>
                }
              />
            );
          })}
        </Tabs>
      </Grid>
      <Grid item xs={4} sm={8} md={12}>
        <TabPanel value={value} index={0}>
          <Grid item xs={4} sm={8} md={12}>
            <CommonLabel label="Kartın Üzerindeki İsim Soyisim" />
            <CommonTextField
              _placeHolder="İsim soyisim giriniz"
              _onChange={(e: string) =>
                setField({ ...values, card_holder_name: e })
              }
              _value={values.card_holder_name}
            />
          </Grid>
          <Grid item xs={4} sm={8} md={6}>
            <CommonLabel label="Kart Numarası" />
            <CommonMaskedInput
              _placeHolder="Örnek:  4111 1111 1111 1111"
              _value={values.card_number}
              _onChange={(e: string) =>
                setField({ ...values, card_number: e })
              }
              _format={"#### #### #### ####"}
            />
          </Grid>
          <Grid item xs={4} sm={8} md={3}>
            <CommonLabel label="Son Kullanım Tarihi" />
            <CommonMaskedInput
              _placeHolder="AA/YY"
              _value={values.expiration_date}
              _onChange={(e: string) =>
                setField({ ...values, expiration_date: e })
              }
              _format={"##/##"}
            />
          </Grid>
          <Grid item xs={4} sm={8} md={3}>
            <CommonLabel label="CVV" />
            <CommonMaskedInput
              _placeHolder="111"
              _value={values.cvc}
              _onChange={(e: string) => setField({ ...values, cvc: e })}
              _format={"###"}
            />
          </Grid>
          {installmentData && installmentData.installmentPrices.length > 0 && (
            <>
              <Grid item xs={4} sm={8} md={6}>
                <PaymentCardVirtual
                  card={card}
                  bank_name={bank_name}
                  card_association={card_association}
                />
              </Grid>
              <Grid item xs={4} sm={8} md={6}>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "22px",
                    color: "#434E58",
                    marginBottom: "8px",
                  }}
                >
                  Taksit Seçenekleri
                </Typography>
                <DynamicInstallmentTable
                  price={price}
                  options={installmentData.installmentPrices}
                  selectedInstallmenOption={selectedInstallmenOption}
                  onCheckboxClicked={onCheckboxClicked}
                />
              </Grid>
            </>
          )}
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default SubscriptionPaymentCards;
