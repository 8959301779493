import { FC, useEffect } from "react";
import { NameLogoBlue } from "../assests";
import { Container, Divider, Grid, Typography } from "@mui/material";
import {
  CommonButton,
  CommonLoading,
  DetailCloseButton,
  DetailFavoriteButton,
  DetailShareButton,
  HeadingTitle,
  SurveyResultSubHeader,
} from "../components";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import parse from "html-react-parser";
import { FavoriteType } from "../client";
import { addItemToFavorite, removeItemFromFavorite } from "../redux/favoriteSlice";
import { openShareContentModal } from "../redux/generalControllerSlice";
import { OpenAPI } from "../client";

const SurveyResult: FC = () => {
  const { id,completed_at } = useParams();
  const { access_token } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate()

  const dispatch = useDispatch<AppDispatch>()
  const { favorite_items } = useSelector((state: RootState) => state.favorite);
  const { survey_logs } = useSelector((state:RootState) => state.log)
  const survey_result = survey_logs.find((e) => e.id == id && e.completed_at == completed_at)

  useEffect(() => {
    if (OpenAPI.TOKEN == undefined && access_token != "") {
      OpenAPI.TOKEN = access_token;
    }
    if(id == undefined || id == null ){
      navigate("/surveys")
    }
  }, [id])

  const addContentToFavorite = async () => {
    try {
      dispatch(
        addItemToFavorite({
          id_of_favorite: survey_result?.survey_token ? survey_result?.survey_token : "",
          name_of_favorite: survey_result?.survey_title ? survey_result.survey_title : "",
          favourite_type: FavoriteType.SURVEY,
        })
      );
    } catch (error: any) {
      console.log("err", error);
    }
  };
  
  const removeContentFromFavorite = () => {
    try {
      dispatch(
        removeItemFromFavorite({
          favorite_item_id: survey_result?.survey_token ? survey_result?.survey_token : "",
        })
      );
    } catch (error) {}
  };
  
  return (
    <>
      <img
        src={NameLogoBlue}
        alt=""
        style={{ marginTop: "20px", marginBottom: "20px" }}
      />
      <Divider style={{ width: "100%", border: "1px solid #E9EBED" }} />
      <CommonLoading loading={false} />
      <Container
        maxWidth="md"
        style={{ marginTop: "40px", marginBottom: "40px" }}
      >
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} sm={8} md={4}>
            <DetailCloseButton
              _onClick={() => navigate("/surveys")}
              customCSS={{ height: "55px" }}
            />
          </Grid>
          <Grid item xs={4} sm={8} md={4}>
            <HeadingTitle title={survey_result?.survey_title ? survey_result.survey_title + " Sonucu" : ""} />
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={4}
            sx={{ display: {xs:"none",sm:"none",md:"flex"}, justifyContent: "flex-end" }}
          >
            <DetailShareButton
              onClick={() => dispatch(openShareContentModal())}
              customCSS={{ height: "55px" }}
            />
            <DetailFavoriteButton
              isLiked={
                favorite_items.find(
                  (e) =>
                    e.favourite_type == FavoriteType.SURVEY && e.id.toString() == survey_result?.survey_token
                )
                  ? true
                  : false
              }
              onClick={
                favorite_items.find(
                  (e) =>
                    e.favourite_type == FavoriteType.SURVEY && e.id.toString() == survey_result?.survey_token
                )
                  ? () => removeContentFromFavorite()
                  : () => addContentToFavorite()
              }
              customCSS={{ marginLeft: "5px", height: "55px" }}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "48px",
              marginBottom: "32px",
            }}
          >
            <SurveyResultSubHeader text={survey_result?.result_title}/>
          </Grid>
          <Grid item xs={4} sm={8} md={12} style={{ marginBottom: "32px" }}>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "28px",
                color: "#171725",
              }}
            >
              {
                parse(survey_result?.result_content ? survey_result.result_content : "")
              }
            </Typography>
          </Grid>
          <Grid item xs={4} sm={8} md={12}>
            <CommonButton
              text="Anasayfaya Dön"
              customCSS={{ width: "max-content", padding: "16px 33px" }}
              onClick={() => navigate("/home")}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SurveyResult;
