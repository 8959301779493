import { FC } from "react";
import { Grid, Typography, Divider, Box } from "@mui/material";
import CommonButton from "./CommonButton";
import CommonTextField from "./CommonTextField";
import { useNavigate } from "react-router-dom";

interface Props {
  id: string;
  onContunieDisabled: () => boolean;
  onContunieTriggered: () => void;
  paidPrice?: number | string;
  activeDiscountCoupon: any;
  currentPhase: string;
  setPhase: (e: string) => void;
  nameOfPackage: string;
  discountCouponCode: string;
  setDiscountCouponCode: (code: string) => void;
  applyDiscountCoupon: () => void;
  discountCouponError: string;
  originalPrice: string;
}

const SubscriptionPaymentSummary: FC<Props> = ({
  id,
  onContunieDisabled,
  onContunieTriggered,
  paidPrice = 0,
  currentPhase,
  setPhase,
  nameOfPackage,
  discountCouponCode,
  setDiscountCouponCode,
  applyDiscountCoupon,
  discountCouponError,
  activeDiscountCoupon,
  originalPrice,
}) => {
  const navigate = useNavigate();

  const hasDiscount = activeDiscountCoupon && activeDiscountCoupon.id;

  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 12 }}
      sx={{ marginTop: { xs: "0px", sm: "0px", md: "30px" } }}
      rowSpacing={3}
    >
      {/* Original Price */}
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "26px",
            color: "#9CA4AB",
          }}
        >
          Toplam
        </Typography>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "26px",
            color: "#171725",
            textDecoration: hasDiscount ? "line-through" : "none",
          }}
        >
          ₺{originalPrice}
        </Typography>
      </Grid>
      {/* Discount Amount */}
      {hasDiscount && (
        <Grid
          item
          xs={4}
          sm={8}
          md={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: "18px",
              lineHeight: "26px",
              color: "#9CA4AB",
            }}
          >
            İndirim ({activeDiscountCoupon.id})
          </Typography>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 600,
              fontSize: "18px",
              lineHeight: "26px",
              color: "#171725",
            }}
          >
            -₺{(Number(originalPrice) - Number(paidPrice)).toFixed(2)}
          </Typography>
        </Grid>
      )}
      {/* Selected Plan */}
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "26px",
            color: "#9CA4AB",
          }}
        >
          Seçtiğiniz Plan
        </Typography>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "26px",
            color: "#171725",
          }}
        >
          {nameOfPackage}
        </Typography>
      </Grid>
      <Divider
        style={{
          width: "100%",
          border: "1px solid #E9EBED",
          marginTop: "24px",
        }}
      />
      {/* Amount to Pay */}
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "26px",
            color: "#9CA4AB",
          }}
        >
          Ödenecek Tutar
        </Typography>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "26px",
            color: "#2574FF",
          }}
        >
          ₺{paidPrice}
        </Typography>
      </Grid>
      {/* Subscription Renewal Info */}
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{
          textAlign: "start",
        }}
      >
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "22px",
            color: "#78828A",
            marginBottom: "10px",
          }}
        >
          Abonelik sürenizin bitiminde aboneliğiniz otomatik yenilenecektir.
        </Typography>
      </Grid>
      {/* Discount Coupon Input */}
      {currentPhase === "payment" && (
        <Grid
          item
          xs={4}
          sm={8}
          md={12}
          style={{
            textAlign: "start",
          }}
        >
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#171725",
              marginBottom: "8px",
            }}
          >
            İndirim Kodu
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <CommonTextField
              _value={discountCouponCode}
              _onChange={(e: string) => setDiscountCouponCode(e)}
              _placeHolder="İndirim kodunuzu girin"
              formName="discountCoupon"
            />
            <CommonButton
              text="Uygula"
              onClick={applyDiscountCoupon}
              disabled={!discountCouponCode}
              customCSS={{
                padding: "8px 18px",
              }}
            />
          </Box>
          {discountCouponError && (
            <Typography
              style={{
                color: "red",
                marginTop: "8px",
              }}
            >
              {discountCouponError}
            </Typography>
          )}
        </Grid>
      )}
      {/* Continue Button */}
      <Grid item xs={4} sm={8} md={12}>
        <CommonButton
          text={currentPhase === "address" ? "Ödeme Adımına Geç" : "Ödemeyi Tamamla"}
          customCSS={{
            paddingTop: "16px",
            paddingBottom: "16px",
            width: "100%",
          }}
          disabled={onContunieDisabled()}
          onClick={onContunieTriggered}
        />
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "26px",
            color: "#9CA4AB",
            marginTop: "16px",
            textDecoration: "underline",
            cursor: "pointer",
            textAlign: "center",
          }}
          onClick={() =>
            currentPhase === "address"
              ? navigate("/show-subscription-packages")
              : setPhase("address")
          }
        >
          Geri
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SubscriptionPaymentSummary;
