import { AxiosResponse } from "axios";
import { contentClient } from "../apiClient";
import { ISurvey, ISurveys } from "../models/survey";

export const GetAllSurveys = async():Promise<ISurveys> => {
    try {
        const response: AxiosResponse<ISurveys> = await contentClient().get("/survey")
        return response.data
    } catch (error) {
        console.error('Error fetching surveys:', error);
        throw new Error('Failed to fetch surveys');
    }
}

export const GetSurvey = async(surveyToken:string):Promise<ISurvey> => {
    try {
        const response: AxiosResponse<ISurvey> = await contentClient().get(`/survey/${surveyToken}`)
        return response.data
    } catch (error) {
        console.error('Error fetching survey:', error);
        throw new Error('Failed to fetch survey');
    }
}

// export const SubmitSurveyAnswer = (): Promise<any> => {
//     try {
        
//     } catch (error) {
//         console.error('Error submitting survey answer:', error);
//         throw new Error('Failed to submit survey answer');
//     }
// }