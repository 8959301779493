import React from 'react';
import Pagination from '@mui/material/Pagination';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const PaginationItem: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {

  return (
    <Pagination count={totalPages} page={currentPage} onChange={(e, value) => onPageChange(value)} variant="outlined" color='primary'  size="large"/>
  )
};

export default PaginationItem;