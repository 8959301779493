import { Container, Grid } from "@mui/material";
import { FC, useEffect, useState } from "react";
import {
  BulletinListingItem,
  CommonLoading,
  HeadingTitle,
  LoadMorePaginationButton,
  PaginationItem,
} from "../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { OpenAPI } from "../client";
import { getBulettins } from "../redux/bulettinSlice";

const Bulletins: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    bulletin,
    loading: bulettin_loading,
    total,
    currentPage,
  } = useSelector((state: RootState) => state.bulettin);

  const DATA_LENGTH_LIMIT = 30;

  useEffect(() => {
    if (bulletin.length == 0) {
      dispatch(
        getBulettins({ perPage: DATA_LENGTH_LIMIT, page: 1, title: "" })
      );
    }
  }, []);

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <CommonLoading loading={bulettin_loading} />
      <Grid
        container
        columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }}
        spacing={2}
        rowSpacing={"30px"}
      >
        <Grid item xs={4} sm={8} md={12} lg={12} style={{ textAlign: "start" }}>
          <HeadingTitle title="Bu Haftanın Bülteni" _marginBottom="0px" />
        </Grid>
        {bulletin.length > 0 && (
          <Grid item xs={4} sm={8} md={3} lg={3}>
            <BulletinListingItem
              id={bulletin[0].id.toString()}
              title={bulletin[0].title}
              is_picture_uploaded={
                bulletin[0].isPictureUploaded
                  ? bulletin[0].isPictureUploaded
                  : false
              }
            />
          </Grid>
        )}
        <Grid
          item
          xs={4}
          sm={8}
          md={12}
          lg={12}
          style={{
            marginTop: "28px",
            textAlign: "start",
          }}
        >
          <HeadingTitle title="Tüm Bültenler" _marginBottom="0px" />
        </Grid>
        {!bulettin_loading &&
          bulletin.map((e, index) => {
            return (
              <Grid item xs={4} sm={8} md={3} key={index}>
                <BulletinListingItem
                  id={e.id.toString()}
                  title={e.title}
                  is_picture_uploaded={
                    e.isPictureUploaded ? e.isPictureUploaded : false
                  }
                />
              </Grid>
            );
          })}
        {total > currentPage * DATA_LENGTH_LIMIT ? (
          <Grid item xs={4} sm={8} md={12} style={{ marginTop: "10px" }}>
            <PaginationItem
              currentPage={currentPage}
              totalPages={Math.ceil(total / DATA_LENGTH_LIMIT)}
              onPageChange={(e) =>
                dispatch(
                  getBulettins({
                    perPage: DATA_LENGTH_LIMIT,
                    page: e,
                    title: "",
                  })
                )
              }
            />
          </Grid>
        ) : null}
      </Grid>
    </Container>
  );
};

export default Bulletins;
