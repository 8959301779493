import { TextField, InputAdornment, Autocomplete } from "@mui/material";
import { FC } from "react";
import { LayoutSearchEnd } from "../assests";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { useNavigate } from "react-router";
import { setLocalSearchWord } from "../redux/searchSlice";

const LayoutSearch: FC = () => {
  const navigate = useNavigate()
  
  const dispatch = useDispatch<AppDispatch>()
  const {loading, search_text} = useSelector((state:RootState) => state.search)

  return (
    <Autocomplete
      id="combo-box-demo"
      loading={loading}
      freeSolo
      value={search_text}
      defaultValue={search_text}
      options={[]}
      sx={{ width: "60%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => dispatch(setLocalSearchWord(e.target.value))}
          variant="outlined"
          size="small"
          placeholder="ParentWiser’da ara"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" style={{cursor:"pointer"}} onClick={() => search_text.length > 0 ? navigate(`/search/${search_text.replaceAll(" ","-")}`): null}>
                <img src={LayoutSearchEnd} alt="" />
              </InputAdornment>
            ),
          }}
          sx={{
            "& fieldset": { border: "1px solid #D1D8DD", padding: "8px 16px" },
            input: {
              color: "#BFC6CC",
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "24px",
            },
            borderRadius: "8px",
            backgroundColor: "#F9F9F9",
          }}
        />
      )}
    />
  );
};

export default LayoutSearch;
