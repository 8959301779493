import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserService, GetTrainingSetsResponse, TrainingSetModel } from '../client';
import { ITrainingSet, ITrainingSetList, ITrainingSetWithArticle } from '../apiClient/models/trainingSet';
import { GetAllTrainingSets, GetTrainingSet } from '../apiClient/services';

export const getAllParentSolutions = createAsyncThunk(
  'parentSolution/getAllParentSolutions',
  async (payload: { perPage:number, page:number, name:string }) => {
    try {
      const { perPage, page, name } = payload
      return await GetAllTrainingSets(perPage, page, name)
    } catch (error: any) {
      throw new Error(error?.message)
    }
  }
);

export const getParentSolution = createAsyncThunk(
  'parentsolution/getParentSolution',
  async (payload: { trainingSetId: string }) => {
    try {
      return await GetTrainingSet(Number(payload.trainingSetId))
    } catch (error: any) {
      throw new Error(error?.message)
    }
  }
)

interface IInitialState {
  trainingSets: {
    data: ITrainingSetList[],
    total:number,
    currentPage:number
  }
  loading: boolean;
  error: string;
  single_parent_solution: ITrainingSetWithArticle;
}

const initialState: IInitialState = {
  trainingSets: {
    data: [] as ITrainingSetList[],
    total:0,
    currentPage:1
  },
  loading: false,
  error: "",
  single_parent_solution: {} as ITrainingSetWithArticle
};

export const parentSolutionSlice = createSlice({
  name: 'parentSolution',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllParentSolutions.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(getAllParentSolutions.fulfilled, (state, action) => {
      // if(state.trainingSets.data.length > 0) {
      //   state.trainingSets.data = [...state.trainingSets.data, ...action.payload.data]
      // }else {
      //   state.trainingSets.data = action.payload.data
      // }
      state.trainingSets.data = action.payload.data

      state.trainingSets.currentPage = action.meta.arg.page
      state.trainingSets.total = action.payload.total
      state.loading = false;
      state.error = ''
    });
    builder.addCase(getAllParentSolutions.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message || ''
    })
    builder.addCase(getParentSolution.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(getParentSolution.fulfilled, (state, action) => {
      state.single_parent_solution = action.payload
      state.loading = false;
    });
    builder.addCase(getParentSolution.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message || ''
    })
  },
});

export default parentSolutionSlice.reducer;