import { AxiosResponse } from "axios";
import { IParentSchools, IParentSchoolWithArticle } from "../models/parentSchool";
import { contentClient } from "../apiClient";
import { IArticle } from "../models/article";

export const GetAllParentSchools = async(perPage:number = 30, page:number = 1, name:string):Promise<IParentSchools> => {
    try {
        const response: AxiosResponse<IParentSchools> = await contentClient().get('/parentSchool', {
            params: {
                page,
                perPage,
                name,
            },
            });
        return response.data;
    } catch (error) {
        console.error('Error fetching parent school:', error);
        throw new Error('Failed to fetch parent school');
    }
}

export const GetParentSchoolModuleArticles = async(id:number):Promise<IParentSchoolWithArticle> => {
    try {
        const articles: AxiosResponse<{data: IArticle[]}> = await contentClient().get(`/article/contentList/${id}/true`)

        const response:IParentSchoolWithArticle = {
            id,
            articles: articles.data.data
        }

        return response

    } catch (error) {
        console.error('Error fetching parent school module:', error);
        throw new Error('Failed to fetch parent school module');
    }
}