import { FC, MouseEvent } from "react";
import { Grid, Typography } from "@mui/material";
import { HeartEmpty, Play, Book, Clock, HeartRed } from "../assests";
import ContentLinearProgressBar from "./ContentLinearProgressBar";
import { Buckets } from "../utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store/store";
import { FavoriteType } from "../client";
import { useNavigate } from 'react-router-dom';

interface Props {
  id: number;
  title: string;
  video_article_count: number;
  text_article_count: number;
  total_watch_time?: number;
  is_picture_uploaded: boolean;
  percentage?:number;
  addContentToFavorite: (
    e: MouseEvent,
    id: string,
    name_of_content: string
  ) => void;
  removeContentFromFavorite: (e: MouseEvent, id: string) => void;
}

const HomeParentSolutionItem: FC<Props> = ({
  id,
  title = "",
  video_article_count = 0,
  text_article_count = 0,
  total_watch_time = 0,
  is_picture_uploaded = false,
  percentage=0,
  addContentToFavorite,
  removeContentFromFavorite,
}) => {
  const navigate = useNavigate()
  const { favorite_items } = useSelector((state: RootState) => state.favorite);

  const cardStyle = {
    display: "flex",
    backgroundColor: "#F9F9F9",
    borderRadius: "24px",
    padding: 24,
  };

  const imageGridStyle = {
    background: `url(${
      is_picture_uploaded ? `${Buckets.ParentSolutionBucket}/${id}.jpg` : ""
    }), #D9D9D9`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "150px",
    borderRadius: "24px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "start",
    marginBottom: "16px",
    padding: 10,
    cursor:"pointer"
  };

  return (
    <Grid container style={cardStyle}>
      <Grid item xs={12} style={imageGridStyle} onClick={() => navigate(`/parentsolution/${id}`)}>
        {favorite_items.some((e) => {
          return e.favourite_type == FavoriteType.TRAINING_SET && e.id == id.toString();
        }) ? (
          <img src={HeartRed} alt="" style={{ cursor: "pointer" }} onClick={(e) => removeContentFromFavorite(e, id.toString())} />
        ) : (
          <img src={HeartEmpty} style={{ cursor: "pointer" }} alt="" onClick={(e) => addContentToFavorite(e, id.toString(), title)} />
        )}{" "}
      </Grid>
      <Grid item xs={12} style={{ textAlign: "left", marginBottom: "19px" }}>
        <Typography
          style={{
            fontSize: "20px",
            fontFamily: "Montserrat",
            lineHeight: "28px",
            color: "#000000",
            fontWeight: "700",
            cursor:"pointer"
          }}
          onClick={() => navigate(`/parentsolution/${id}`)}
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <img src={Play} alt="" style={{ marginRight: "5px" }} />
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "400",
            fontSize: "12px",
            color: "#9CA4AB",
            lineHeight: "18px",
          }}
        >
          {video_article_count} Video
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={Book} alt="" style={{ marginRight: "5px" }} />
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "400",
            fontSize: "12px",
            color: "#9CA4AB",
            lineHeight: "18px",
          }}
        >
          {text_article_count} Makale
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <img src={Clock} alt="" style={{ marginRight: "5px" }} />
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "400",
            fontSize: "12px",
            color: "#9CA4AB",
            lineHeight: "18px",
          }}
        >
          {total_watch_time}'
        </Typography>
      </Grid>
      <Grid xs={12} style={{ marginTop: "19px" }}>
        <ContentLinearProgressBar percentage={percentage} />
      </Grid>
    </Grid>
  );
};

export default HomeParentSolutionItem;
